
import { Vue, Component } from "vue-property-decorator";
import { ListDataSource } from "@/data/List/ListDataSource";
@Component
export default class Home extends Vue {
  dataSource: any = new ListDataSource({
    config: {
      pageIndex: 1,
      pageSize: 15,
    },
    className: "region",
  });



  get breadcrumbs() {
    return [
      {
        text: "Главная",
        to: "/",
      },
      {
        text: "Страны и города",
        disabled: true,
      },
    ];
  }
}
